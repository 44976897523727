import {
  UPDATE_ASPERA_STATUS,
  UPDATE_ASPERA_TRANSFER
} from './types'

const initialState = {
  status: '',
  iterations: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_ASPERA_STATUS:
      return {
        ...state,
        status: action.payload.status
      }
    case UPDATE_ASPERA_TRANSFER:
      return {
        ...state,
        iterations: {
          ...state.transfers,
          [action.payload.uuid]: action.payload.iteration
        }
      }
  default:
    return state
  }
}