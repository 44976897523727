/** @jsx jsx */
import React from 'react'
import styled from '@emotion/styled'
import { css, jsx, Global } from '@emotion/core'
import ReactPaginate from 'react-paginate'
import SkeletonLoader from './components/SkeletonLoader'

const TableWrapper = styled.div`
  box-shadow: 0 2px 7px 0 rgba(224,227,228,0.62);
  border-top: 1px solid #f4f4f4;
  border-left: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
`

const RowWrapper = styled.div`
  border-bottom: 1px solid #d3d3d3;
`

const PaginationContainer = styled.div`
  position: relative;
`

const PaginationLoadingOverlay = styled.div`
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  background: rgba(249, 249, 249, 0.5);
`

const Header = styled.div`
  padding: 6px 15px;
  font-size: 0.85em;
  color: #999;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 4px;
`

class Table extends React.Component {
  static defaultProps = {
    pagination: null,
    loading: false,
    error: false,
    header: [],
    widths: []
  }
  render() {
    const { data, renderer, pagination, loading, widths, header } = this.props
    return (
      <>
        <Global
          styles={css`
            .cs-paginate-disabled {
              display: none;
            }
          `}
        />
        {header.length > 0 && (
          <Header className='flex'>
            {header.map((header, i) =>
              <div key={header} className={widths[i] === 'auto' ? 'flex-auto' : null} css={widths[i] !== 'auto' ? css`
              width: ${widths[i]}px;
              ` : null}>{header}</div>
            )}
          </Header>
        )}
        <div className='py1'>
          <SkeletonLoader isLoading={loading} hasResults={data.length > 0} />
          {data.length > 0 && (
            <TableWrapper>
              {data.map(item =>
                <RowWrapper key={item._key}>
                  {React.createElement(renderer, {
                    ...item,
                    ...{ widths }
                  })}
                </RowWrapper>
              )}
            </TableWrapper>
          )}
        </div>
        {null !== pagination && pagination.pageCount > 1 && (
          <PaginationContainer>
            {(loading || !data.length) && <PaginationLoadingOverlay />}
            <ReactPaginate
              containerClassName='flex items-stretch justify-end py2 list-style-none mt2 cs-paginate'
              previousLabel='Back'
              pageCount={pagination.pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              disabledClassName='cs-paginate-disabled'
              forcePage={pagination.currentPage}
              onPageChange={e => pagination.onPageChange(e.selected)} />
          </PaginationContainer>
        )}
      </>
    )
  }
}

export default Table