const config = {
  colors: {
    gray_dark: '#2A2A2A',
    gray_med: '#373839',
    green: '#087f23',
    red: '#ff0000'
  },

  shadows: {
    light: 'inset 0 1px 0 1px rgba(255,255,255,0.06), 0 1px 0 0 rgba(22,29,37,0.1)'
  }
}

export default config