import {
  GET_TEAM_MEMBERS,
  UPDATE_TEAM_INVITE,
  INVITE_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER
} from '../../redux/team/types'

import { get, post, del } from '../fetch'
import { toast } from "react-toastify"

export const getTeamMembers = () => (dispatch, getState) => dispatch(
  get(GET_TEAM_MEMBERS, `organizations/${getState().user.details.organization.id}/users`)
)

export const inviteTeamMember = () => (dispatch, getState) => dispatch(
  post(INVITE_TEAM_MEMBER, `organizations/${getState().user.details.organization.id}/users`, {
    email: getState().team_members.invite.email
  }, res => {
    if(res.success) {
      toast.success("Invite sent", {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  })
)

export const removeTeamMember = (user) => (dispatch, getState) => dispatch(
  del(REMOVE_TEAM_MEMBER, `organizations/${getState().user.details.organization.id}/users/${user.id}`, {}, res => {
    if(res.success) {
      toast.success("User removed", {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  })
)

export const updateTeamInvite = (key, value) => dispatch => dispatch({
  type: UPDATE_TEAM_INVITE,
  payload: { key, value }
})