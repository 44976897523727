export const LOGIN = 'LOGIN'
export const LOGIN_PENDING = 'LOGIN_PENDING'
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED'
export const LOGIN_REJECTED = 'LOGIN_REJECTED'
export const UPDATE_AUTH_FIELD = 'UPDATE_AUTH_FIELD'
export const LOGOUT = 'LOGOUT'
export const SET_JWT_TOKEN = 'SET_JWT_TOKEN'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING'
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED'
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED'
export const SET_PASSWORD = 'SET_PASSWORD'
export const SET_PASSWORD_PENDING = 'SET_PASSWORD_PENDING'
export const SET_PASSWORD_FULFILLED = 'SET_PASSWORD_FULFILLED'
export const SET_PASSWORD_REJECTED = 'SET_PASSWORD_REJECTED'
