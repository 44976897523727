import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducer'
import aspera from './aspera/reducer'
import auth from './auth/reducer'
import distributor_uploads from './distributor_uploads/reducer'
import team_members from './team/reducer'
import invite from './invite/reducer'
import profile from './profile/reducer'
import security from './security/reducer'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  user,
  aspera,
  auth,
  distributor_uploads,
  team_members,
  invite,
  profile,
  security
})

export default createRootReducer