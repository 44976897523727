import {
  GET_USER
} from '../../redux/user/types'

import { get } from '../fetch'

export const getUser = () => dispatch => dispatch(
  get(GET_USER, `auth`)
)


