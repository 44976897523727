/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { css, keyframes, jsx } from '@emotion/core'

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Spinner = () => 
  <div css={css`
    display: inline-block;
    width: 40px;
    height: 40px;
    :after {
      content: " ";
      display: block;
      width: 32px;
      height: 32px;
      margin: 4px;
      border-radius: 50%;
      border: 6px solid #fb0f3b;
      border-color: #fb0f3b transparent #fb0f3b transparent;
      animation: ${spin} 1.2s linear infinite;
    }
  `}></div>

export default Spinner