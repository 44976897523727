import {
  GET_DISTRIBUTOR_UPLOADS,
  CREATE_DISTRIBUTOR_UPLOAD,
  UPDATE_DISTRIBUTOR_UPLOAD,
  DELETE_DISTRIBUTOR_UPLOAD,
  UPDATE_DISTRIBUTOR_UPLOAD_STATUS
} from './types'

const initialState = {
  status: 'PENDING',
  list: [],
  pagination: {
    perPage: 20,
    currentPage: 0,
    totalItems: 0
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_DISTRIBUTOR_UPLOADS.PENDING:
      return {
        ...state,
        status: 'PENDING',
        list: [],
      }
    case GET_DISTRIBUTOR_UPLOADS.FULFILLED:
    case CREATE_DISTRIBUTOR_UPLOAD.FULFILLED:
    case DELETE_DISTRIBUTOR_UPLOAD.FULFILLED:
      return {
        ...state,
        status: 'COMPLETE',
        list: action.payload.data.distributorUploads,
        pagination: action.payload.data.pagination
      }
    case GET_DISTRIBUTOR_UPLOADS.FAILED:
    case CREATE_DISTRIBUTOR_UPLOAD.FAILED:
      return {
        ...state,
        status: 'FAILED',
        list: [],
      }
    case UPDATE_DISTRIBUTOR_UPLOAD.FULFILLED:
      return {
        ...state,
        list: state.list.map(upload => {
          if (upload.id === action.payload.data.distributorUpload.id) {
            return action.payload.data.distributorUpload
          }
          return upload
        })
      }
    case UPDATE_DISTRIBUTOR_UPLOAD_STATUS:
      return {
        ...state,
        list: state.list.map(upload => {
          if (upload.id === action.payload.uploadID) {
            return {
              ...upload,
              progress: action.payload.progress,
              speed_in_mbps: action.payload.speed,
              ms_remaining: action.payload.msRemaining
            }
          }
          return upload
        })
      }
  default:
    return state
  }
}