/** @jsx jsx */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getInvite, updateInviteForm, updateInvite } from '../../api/invite'
import { css, jsx } from '@emotion/core'
import { Form, Input, Button, Card, Error } from '../../components/AuthForm'
import '../../index.css'
import '../../containers/Auth/index.css'
import ImageLow from '../../img/airline@lowrez.jpg'
import ImageHigh from '../../img/airline@1080.jpg'
import GlobalLoader from '../../components/GlobalLoader'
import NotFound from '../NotFound'

class Invite extends React.Component {
  constructor() {
    super()
    this.image = null
    this.state = {
      src: ImageLow
    }
  }
  componentDidMount() {
    this.props.getInvite(this.props.match.params.token)
    this.image = new Image()
    this.image.src = ImageHigh
    this.image.onload = () => this.setState({ src: ImageHigh })
  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { user, authUser, isLoading, hasFailed, organization, sender, form, updateInviteForm, updateInvite } = this.props

    if(isLoading) {
      return <GlobalLoader />
    }

    if(hasFailed) {
      return <NotFound />
    }

    if(authUser.isAuthed && authUser.details.email !== user.email) {
      return <NotFound />
    }

    return (
      <div>
        <div className="not-authed-body" css={css`
          background-image: url(${this.state.src});
        `}>
          <div className="auth-form">
            <Card>
              <h3 className='mb2'>Welcome to CineSend!</h3>
              <p>
                You have been invited to join <strong>{organization.name}</strong>{sender.name && <> by <strong>{sender.name}</strong></>}! {" "}
                {user.requires_setup ? (
                  <span>Fill in the fields below to complete your account setup and accept the invitation.</span>
                ) : (
                  <span>Please login below to accept this invitation.</span>
                )}
              </p>
              {authUser.isAuthed ? (
                <>
                  <button className='cs-button' disabled={form.isSubmitting} onClick={() => {
                    updateInvite(this.props.match.params.token)
                  }}>Join {organization.name}</button>
                </>
              ) : (
                <Form onSubmit={(e) => {
                  e.preventDefault()
                }}>
                  <label>Email Address</label>
                  <Input
                    type="text" 
                    value={user.email} 
                    onChange={e => {}}
                    placeholder="Enter your email..." 
                    disabled={true} />

                  {user.requires_setup ? (
                    <>
                      <label>What's your name?</label>
                      <Input
                        type="text" 
                        value={form.name} 
                        onChange={e => updateInviteForm('name', e.target.value)}
                        placeholder="Enter your name..." 
                        disabled={form.isSubmitting} />

                      <label>Create a password</label>
                      <Input
                        type="password" 
                        value={form.password} 
                        onChange={e => updateInviteForm('password', e.target.value)}
                        placeholder="Enter a password..." 
                        disabled={form.isSubmitting} />

                      <label>Confirm your password</label>
                      <Input
                        type="password" 
                        value={form.confirm_password} 
                        onChange={e => updateInviteForm('confirm_password', e.target.value)}
                        placeholder="Re-enter your password..." 
                        disabled={form.isSubmitting} />
                    </>
                  ) : (
                    <>
                      <label>Password</label>
                      <Input
                        type="password" 
                        value={form.password} 
                        onChange={e => updateInviteForm('password', e.target.value)}
                        placeholder="Enter your password..." 
                        disabled={form.isSubmitting} />
                    </>
                  )}

                  <Button onClick={() => {
                    updateInvite(this.props.match.params.token)
                  }} disabled={form.isSubmitting || form.password != form.confirm_password}>
                    {user.requires_setup ? (
                      <>Create Account &amp; Accept Invite</>
                    ) : (
                      <>Login &amp; Accept Invite</>
                    )}
                  </Button>

                  {form.error.length > 0 && (
                    <Error className="mt2">{form.error}</Error>
                  )}
                </Form>
              )}
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getInvite: bindActionCreators(getInvite, dispatch),
  updateInviteForm: bindActionCreators(updateInviteForm, dispatch),
  updateInvite: bindActionCreators(updateInvite, dispatch)
})

const mapStateToProps = state => ({
  isLoading: state.invite.status === 'PENDING',
  hasFailed: state.invite.status === 'FAILED',
  user: state.invite.user,
  organization: state.invite.organization,
  sender: state.invite.sender,
  form: state.invite.form,
  authUser: state.user
})

export default connect(mapStateToProps, mapDispatchToProps)(Invite)