export const GET_DISTRIBUTOR_UPLOADS = {
  REQUEST: 'GET_DISTRIBUTOR_UPLOADS',
  PENDING: 'GET_DISTRIBUTOR_UPLOADS_PENDING',
  FULFILLED: 'GET_DISTRIBUTOR_UPLOADS_FULFILLED',
  FAILED: 'GET_DISTRIBUTOR_UPLOADS_FAILED'
}

export const DELETE_DISTRIBUTOR_UPLOAD = {
  REQUEST: 'DELETE_DISTRIBUTOR_UPLOAD',
  PENDING: 'DELETE_DISTRIBUTOR_UPLOAD_PENDING',
  FULFILLED: 'DELETE_DISTRIBUTOR_UPLOAD_FULFILLED',
  FAILED: 'DELETE_DISTRIBUTOR_UPLOAD_FAILED'
}

export const UPDATE_DISTRIBUTOR_UPLOAD = {
  REQUEST: 'UPDATE_DISTRIBUTOR_UPLOAD',
  PENDING: 'UPDATE_DISTRIBUTOR_UPLOAD_PENDING',
  FULFILLED: 'UPDATE_DISTRIBUTOR_UPLOAD_FULFILLED',
  FAILED: 'UPDATE_DISTRIBUTOR_UPLOAD_FAILED'
}

export const CREATE_DISTRIBUTOR_UPLOAD = {
  REQUEST: 'CREATE_DISTRIBUTOR_UPLOAD',
  PENDING: 'CREATE_DISTRIBUTOR_UPLOAD_PENDING',
  FULFILLED: 'CREATE_DISTRIBUTOR_UPLOAD_FULFILLED',
  FAILED: 'CREATE_DISTRIBUTOR_UPLOAD_FAILED'
}

export const UPDATE_DISTRIBUTOR_UPLOAD_STATUS = 'UPDATE_DISTRIBUTOR_UPLOAD_STATUS'