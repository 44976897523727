import React from 'react'
import { connect } from 'react-redux'
import { getDistributorUploads } from '../../api/distributor_uploads'
import { bindActionCreators } from 'redux'
import DistributorUploadList from '../../components/DistributorUploadList'

class DistributorUploads extends React.Component {
  componentDidMount() {
    this.props.getDistributorUploads()
  }
  render() {
    return <DistributorUploadList onPageChange={page => this.props.getDistributorUploads(page)}/>
  }
}

const mapDispatchToProps = dispatch => ({
  getDistributorUploads: bindActionCreators(getDistributorUploads, dispatch)
})

export default connect(null, mapDispatchToProps)(DistributorUploads)