import styled from 'styled-components';

const Card = styled.div`
  box-sizing: border-box;
  max-width: 410px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255,255,255,0.50);
  border-radius: 4px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  padding: 1rem;
  border: 1px solid #999;
  margin-bottom: 1rem;
  font-size: 0.8rem;
  opacity: ${props => props.disabled ? '0.5' : '1'};
`;

const Button = styled.button`
  background: linear-gradient(180deg, #ff4063 0%, #e42f50 100%);
  border-color: #ff4063;
  border-radius: 3px;
  padding: 1rem;
  color: white;
  font-weight: 700;
  width: 100%;
  font-size: 0.8rem;
  cursor: pointer;
  opacity: ${props => props.disabled ? '0.5' : '1'};
`;

const Logo = styled.img`
  width: 50%;
  margin-bottom: 1rem;
`;

const Error = styled.div`
  color: red;
  text-align: center;
`;

const Success = styled.div`
  color: green;
  text-align: center;
`;

export { Form, Input, Button, Logo, Card, Error, Success };