/** @jsx jsx */
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { css, jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import Tag from '../../package/src/components/Tag'
import msToTime from '../../helpers/msToTime'
import { initAspera } from '../../api/aspera'
import { deleteFailedDistributorUpload } from '../../api/distributor_uploads'
import { ButtonDropdown } from '@bitcine/cs-theme'
import * as fs from 'filesize'
import dayjs from 'dayjs'

const pulse = keyframes`
  0% { opacity: 1; } 
  50% { opacity: 0.5; } 
  100% { opacity: 1; }
`
const rowHeight = 48

const Container = styled.div`
  position: relative;
  height: ${rowHeight}px;
	border-radius: 2px;
  background-color: #FFFFFF;
  width: 100%;
  color: #424A50;
`
const Content = styled.div`
  height: 100%;
  width: 100%;
  line-height: 1em;
` 

const ProgressBar = styled.div`
  opacity: .15;
  z-index: 1;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 3px;
  background: #FB0F3B;
  transition: width 750ms;
  width: ${props => props.progress}%;
  animation: ${pulse} linear 3s infinite;
`

const Small = styled.span`
  opacity: 0.75;
`

const Spinning = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const SpinnerIcon = styled.span`
  animation: ${Spinning} 2.4s linear infinite;
`

class FileRowRenderer extends React.Component {
  render() {
    const { id, filename, progress, status, speed_in_mbps, ms_remaining, filesize, initAspera, deleteFailedDistributorUpload, widths, user, created_at } = this.props
    return (
      <Container className='flex px2'>
        <Content className='flex items-center'>
          <div className={`flex items-center ${status === 'failed' ? 'red bold' : ''} ${widths.length === 0 || widths[0] === 'auto' ? 'flex-auto' : ''}`}>
            {filename}
          </div>

          <Small className='flex items-center' css={widths.length > 0 && widths[1] ? css`width: ${widths[1]}px;` : null}>
            {status === 'uploading' && <SpinnerIcon className='material-icons mr1'>autorenew</SpinnerIcon>}
            <span className='capitalize'>{status}</span>
          </Small>
          <Small css={widths.length > 0 && widths[2] ? css`width: ${widths[2]}px;` : null}>
            {status === 'failed' 
              ? <span>{progress}% uploaded by {user ? user.name : 'N/A'}</span> 
              : status === 'uploading' 
                ? <span>{progress}% - {speed_in_mbps} mbps -&nbsp;<strong>{msToTime(ms_remaining)} Remaining</strong></span>
                : <span>{dayjs(created_at).format('MMM D, YYYY')} by {user ? user.name : 'N/A'}</span>}
          </Small>
          <Small css={widths.length > 0 && widths[3] ? css`width: ${widths[3]}px;` : null}>
            {Number.isInteger(filesize) ? fs(filesize) : ''}
          </Small>
          <Small css={widths.length > 0 && widths[4] ? css`width: ${widths[4]}px;` : null}>
            {status === 'failed' && 
              <ButtonDropdown 
                button={{
                  menu: true,
                  className: 'link material-icons',
                  text: 'more_vert',
                }}
                dropdown={{
                  clickCloses: true,
                  content: [
                    {
                      text: 'Retry',
                      icon: 'refresh',
                      onClick: () => initAspera(id)
                    },
                    {
                      text: 'Delete',
                      icon: 'delete',
                      onClick: () => deleteFailedDistributorUpload(id)
                    }
                  ]
                }} />}
          </Small>

          {{
            'queued': <Tag>Queued</Tag>,
            'preparing': <Tag>Preparing</Tag>,
            'stopped': <Tag>Stopped</Tag>
          }[status]}
        </Content>
        {status === 'uploading' && <ProgressBar progress={progress} />}
      </Container>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  initAspera: bindActionCreators(initAspera, dispatch),
  deleteFailedDistributorUpload: bindActionCreators(deleteFailedDistributorUpload, dispatch)
})

export default connect(null, mapDispatchToProps)(FileRowRenderer)