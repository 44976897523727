import {
  GET_USER_PENDING,
  GET_USER_FULFILLED,
  GET_USER_REJECTED
} from './types'

import {
  LOGIN_FULFILLED,
  LOGOUT
} from '../auth/types'

import {
  UPDATE_INVITE_FULFILLED
} from '../invite/types'

import {
  UPDATE_PROFILE_FULFILLED
} from '../profile/types'

const initialState = {
  status: 'PENDING',
  isAuthed: false,
  details: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_USER_PENDING: 
      return {
        ...state,
        status: 'PENDING',
        isAuthed: false,
        details: {}
      }
    case GET_USER_FULFILLED:
    case LOGIN_FULFILLED:
    case UPDATE_INVITE_FULFILLED:
    case UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        status: 'READY',
        isAuthed: true,
        details: action.payload.data.user
      }
    case GET_USER_REJECTED:
    case LOGOUT:
      return {
        ...state,
        status: 'READY',
        isAuthed: false,
        details: {}
      }
  default:
    return state
  }
}