/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { updateField, resetPassword } from '../../api/auth'
import { bindActionCreators } from 'redux'
import { Form, Input, Button, Card, Success } from '../../components/AuthForm'
import { Link } from 'react-router-dom'
import Nav from '../Nav/index'

import '../../index.css'
import './index.css'

import ImageLow from '../../img/seats_faded@lowrez.jpg'
import ImageHigh from '../../img/seats_faded@1080.jpg'

class Reset extends React.Component {
  constructor() {
    super()
    this.image = null
    this.state = {
      src: ImageLow
    }
  }
  componentDidMount() {
    this.image = new Image()
    this.image.src = ImageHigh
    this.image.onload = () => this.setState({ src: ImageHigh })
  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { auth, updateField, resetPassword } = this.props
    return (
      <div>
        <Nav />
        <div className="not-authed-body" css={css`
          background-image: url(${this.state.src});
        `}>
          <div className="auth-form">
            <Card className='mb2'>
              <h1 className='mb2'>Password Reset</h1>
              <Form onSubmit={(e) => {
                e.preventDefault()
              }}>
                <Input
                  autoFocus
                  type="text" 
                  value={auth.emailAttempt} 
                  onChange={e => updateField('emailAttempt', e.target.value )}
                  placeholder="Enter your email..." 
                  disabled={auth.isSubmitting} />

                <Button onClick={resetPassword} disabled={auth.isSubmitting}>Reset</Button>

                {auth.resetEmailSent && (
                  <Success className="mt2">Passord reset has been sent.</Success>
                )}
              </Form>
            </Card>
            <Card>
              <Link to='/'>Login</Link>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  updateField: bindActionCreators(updateField, dispatch),
  resetPassword: bindActionCreators(resetPassword, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Reset)