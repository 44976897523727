import React from 'react'
import { connect } from 'react-redux'
import Table from '../../package/src/components/Table'
import FileRowRenderer from '../FileRowRenderer'

class DistributorUploadList extends React.Component {

  render() {
    const { 
      distributorUploads,
      pageCount,
      currentPage,
      onPageChange,
      isLoading,
      hasFailed
    } = this.props

    return (
      <>
        <Table
          loading={isLoading}
          error={hasFailed}
          data={distributorUploads.map(upload => ({ ...upload, _key: upload.id }))}
          renderer={FileRowRenderer}
          pagination={{
            pageCount,
            currentPage,
            onPageChange: e => onPageChange(e)
          }}
          header={['File Name', 'Status', 'Uploaded Date', 'Size', ' ']}
          widths={['auto', 200, 300, 100, 45]}
        />
      </>
    )
  }
}



const mapStateToProps = state => ({
  isLoading: state.distributor_uploads.status === 'PENDING',
  hasFailed: state.distributor_uploads.status === 'FAILED',
  distributorUploads: state.distributor_uploads.list,
  pageCount: Math.ceil(state.distributor_uploads.pagination.totalItems / state.distributor_uploads.pagination.perPage),
  currentPage: state.distributor_uploads.pagination.currentPage
})

export default connect(mapStateToProps)(DistributorUploadList)