/* global AW4 */
import {
  UPDATE_ASPERA_STATUS,
  UPDATE_ASPERA_TRANSFER
} from '../../redux/aspera/types'

import {
  UPDATE_DISTRIBUTOR_UPLOAD,
  CREATE_DISTRIBUTOR_UPLOAD,
  UPDATE_DISTRIBUTOR_UPLOAD_STATUS
} from "../../redux/distributor_uploads/types"


import uuidv4 from "uuid/v4"
import { post, put } from '../fetch'

/*
 *.This function initializes Aspera and prompts the user to select one or more files
 */
export const initAspera = (distributorUploadId = null) => dispatch => {

  dispatch(updateAsperaStatus('INIT'))

  const uuid = uuidv4()
  const sdkLocation = "//d3gcli72yxqn2z.cloudfront.net/connect/v4"

  const asperaWeb = new AW4.Connect({
    sdkLocation,
    minVersion: "3.6.0",
    id: uuid
  })

  const asperaInstaller = new AW4.ConnectInstaller({ sdkLocation })

  asperaWeb.initSession(`nodeConnect-${uuid}`)

  asperaWeb.addEventListener(AW4.Connect.EVENT.STATUS, (eventType, status) =>
    dispatch(asperaWebEventListener(eventType, status, asperaInstaller))
  )

  asperaWeb.addEventListener("transfer", (e, asperaObject) => 
    dispatch(asperaTransferEvents(e, asperaObject, uuid))
  )

  asperaWeb.showSelectFileDialog({
    success: ({ dataTransfer }) => {
      dispatch(updateAsperaStatus(''))
      if (!dataTransfer.files || !dataTransfer.files.length) {
        asperaWeb.stop()
        return
      }
      dataTransfer.files.forEach(file => dispatch(initializeTransfer(asperaWeb, file, distributorUploadId)))
    },
    error: res => {
      console.log(res)
      alert(res.data.message || 'Failed to upload, please try again')
    }
  }, null)
}

/*
 *.This function will make a POST call to the server to create an upload model and return a destination in S3
 */
const initializeTransfer = (asperaWeb, file, distributorUploadId) => dispatch => {
  const fileUuid = uuidv4()
  const fileObject = {
    ...file,
    uuid: fileUuid,
    filename: file.name.split("/").pop(),
    progress: 0,
    status: 'PREPARING',
    speed: 0,
    msRemaining: 0
  }
  dispatch(
    post(
      CREATE_DISTRIBUTOR_UPLOAD.REQUEST, 
      `distributor/uploads`, 
      {
        filename: fileObject.filename,
        filesize: fileObject.size,
        distributor_upload_id: distributorUploadId
      }, 
      res => {
        const file = {
          ...fileObject,
          ...{ user: res.data.upload.user }
        }
        dispatch(startTransfer(file, asperaWeb, res.data.aspera, res.data.upload))
      }, 
      res => {
        console.log(res)
        alert(res.data.message || `Failed to upload ${file.name}, please try again`)
      }
    )
  )
}

/*
 *.This function will start the upload using Aspera Connect
 */
const startTransfer = (file, asperaWeb, asperaSetup, upload) => dispatch => {
  const transferSpec = {
    ...asperaSetup.transfer_specs[0].transfer_spec,
    tags: {
      aspera: {
        "cloud-metadata": [{ "Content-Disposition": 'attachment; filename="' + file.filename + '"' }]
      }
    },
    paths: [
      {
        source: file.name
      }
    ],
    create_dir: false,
    destination_root: upload.key,
    authentication: "token"
  }
  const connectSettings = {
    allow_dialogs: "no",
    use_absolute_destination_path: false,
    upload_details: {
      ...upload,
      uuid: file.uuid
    }
  }
  asperaWeb.startTransfer(transferSpec, connectSettings)
  dispatch(setAsperaTransferIterationCount(file.uuid, 0))
}

const asperaWebEventListener = (eventType, dataStatus, asperaInstaller) => (dispatch, getState) => {
  const status = AW4.Connect.STATUS
  if (eventType === AW4.Connect.EVENT.STATUS) {
    switch (dataStatus) {
      case status.INITIALIZING:
        asperaInstaller.showLaunching()
        break
      case status.FAILED:
        asperaInstaller.showDownload()
        break
      case status.OUTDATED:
        asperaInstaller.showUpdate()
        break
      case status.RUNNING:
        asperaInstaller.connected()
        break
      case status.RETRYING:
        break
      default:
        break
    }
  }
}

const asperaTransferEvents = (event, asperaObject, uuid) => (dispatch, getState) => {
  asperaObject.transfers
    .filter(transfer => transfer.aspera_connect_settings.app_id === `nodeConnect-${uuid}`)
    .forEach(transfer => {
      const uploadDetails = transfer.aspera_connect_settings.upload_details
      switch (transfer.status) {
        case "initiating":
          // dispatch(updateFileUpload(uploadDetails.uuid, 'status', 'UPLOADING'))
          break
        case "queued":
          // dispatch(updateFileUpload(uploadDetails.uuid, 'status', 'QUEUED'))
          break
        case "running":
          dispatch(updateUpload(uploadDetails, transfer))
          break
        case "completed":
          dispatch(completeUpload(uploadDetails, transfer))
          break
        case "removed":
          // dispatch(removeFileUpload(uploadDetails.uuid))
          break
        case "cancelled":
          // dispatch(updateFileUpload(uploadDetails.uuid, 'status', 'STOPPED'))
          break
        case "failed":
          // dispatch(updateFileUpload(uploadDetails.uuid, 'status', 'FAILED'))
          break
        default:
          break
      }
    })
}

const updateUpload = (uploadDetails, transfer) => (dispatch, getState) => {
  const progress = Math.round((transfer.bytes_written / transfer.bytes_expected) * 100)
  const speed = Math.ceil(transfer.calculated_rate_kbps / 1024).toFixed(0)
  const msRemaining = transfer.remaining_usec / 1000
  const iteration = getState().aspera.iterations[uploadDetails.uuid]
  if (iteration % 30 === 0) {
    dispatch(put(UPDATE_DISTRIBUTOR_UPLOAD.REQUEST, `distributor/uploads/${uploadDetails.id}`, {
      status: 'uploading',
      speed_in_mbps: speed,
      ms_remaining: msRemaining,
      progress,
      filesize_progress: transfer.bytes_written
    }))
  }
  dispatch(updateAsperaTransferProgress(uploadDetails.id, progress, speed, msRemaining))
  dispatch(setAsperaTransferIterationCount(uploadDetails.uuid, iteration + 1))
}

const completeUpload = (uploadDetails, transfer) => dispatch => {
  dispatch(
    put(
      UPDATE_DISTRIBUTOR_UPLOAD.REQUEST,
      `distributor/uploads/${uploadDetails.id}`, 
      {
        status: 'uploaded',
        speed_in_mbps: 0,
        ms_remaining: 0,
        progress: 100,
        filesize_progress: transfer.bytes_expected
      }
    )
  )
}

const updateAsperaTransferProgress = (uploadID, progress, speed, msRemaining) => dispatch => dispatch({
  type: UPDATE_DISTRIBUTOR_UPLOAD_STATUS,
  payload: { uploadID, progress, speed, msRemaining }
})

const setAsperaTransferIterationCount = (uuid, iteration = 0) => dispatch => dispatch({ 
  type: UPDATE_ASPERA_TRANSFER, 
  payload: { uuid, iteration }
})

export const updateAsperaStatus = status => dispatch => dispatch({
  type: UPDATE_ASPERA_STATUS,
  payload: {
    status
  }
})
