import React from 'react'

class NotFound extends React.Component {

  render() {
    return (
      <div className='center mt4 pt4'>
        <h2>404 - Page Not Found</h2>
      </div>
    )
  }
}

export default NotFound