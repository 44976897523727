import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from '@emotion/styled'
import { FiMoreVertical } from "react-icons/fi"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { removeTeamMember } from '../../../../api/team'

const Card = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 7px 0 rgba(224,227,228,0.62);
  border-radius: 2px;
  padding: 24px;
  opacity: ${props => props.isPending ? '0.5' : '1'};
`

const Icon = styled.div`
  height: 86px;
  width: 86px;
  border-radius: 43px;
  background-color: #D8D8D8;
  color: #545C63;
  line-height: 86px;
  text-align:center;
  font-size: 32px;
  font-weight: bold;
  margin: 0 auto 20px auto;
`

const Name = styled.div`
  color: #545C63;
  text-align:center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`
const Email = styled.div`
  color: #545C63;
  text-align:center;
  font-size: 16px;
`

const TopTag = styled.span`
  position: absolute;
  top: 5px;
  left: 5px;
  background: #333;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.75em;
  padding: 5px 8px;
  border-radius: 3px;
  line-height: 1;
`

const Menu = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
`

const MenuIcon = styled.div`
  font-size: 1.7em;
`

class UserCard extends React.Component {
  getInitials = name => {
    const split = name.split(" ")
    return split[0].charAt(0) + (typeof split[1] === "string" ? split[1].charAt(0) : "")
  }
  render() {
    const { user, removeTeamMember, authUser } = this.props
    const isPending = !user.pivot.is_active
    const noName = !user.name || user.name.length === 0
    const isAuthUser = authUser.details.id === user.id
    return (
      <Card isPending={isPending}>
        {isPending && <TopTag>Pending</TopTag>}
        {isAuthUser && <TopTag>You</TopTag>}
        {!isAuthUser && (
          <Menu>
            <ButtonDropdown
              button={{
                className: 'link',
                text: (
                  <MenuIcon><FiMoreVertical /></MenuIcon>
                )
              }}
              dropdown={{
                clickCloses: true,
                content: [
                  {
                    text: 'Remove user from organization',
                    onClick: () => {
                      if(window.confirm('Are you sure?')) {
                        removeTeamMember(user)
                      }
                    }
                  }
                ]
              }}
            />
          </Menu>
        )}
        <Icon>{noName ? '' : this.getInitials(user.name)}</Icon>
        <Name>{noName ? '' : user.name}&nbsp;</Name>
        <Email>{user.email}</Email>
      </Card>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  removeTeamMember: bindActionCreators(removeTeamMember, dispatch)
})

const mapStateToProps = state => ({
  authUser: state.user
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCard)