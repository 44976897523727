import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { initAspera } from '../../api/aspera'
import AsperaLogo from '../../img/aspera.svg'

const AsperaButton = ({ status, initAspera }) => 
  <button 
    className='cs-button white flex items-center'
    disabled={status === 'INIT'}
    onClick={() => initAspera(null)}>
    <img src={AsperaLogo} width="30" alt="Aspera" />
    Upload with Aspera
  </button>

const mapStateToProps = ({ aspera }) => ({
  status: aspera.status
})

const mapDispatchToProps = dispatch => ({
  initAspera: bindActionCreators(initAspera, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AsperaButton)

