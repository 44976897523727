/** @jsx jsx */
import React from 'react'
import { jsx, css } from '@emotion/core'
import { logout } from '../../api/auth'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ButtonDropdown } from "@bitcine/cs-theme"
import { MdKeyboardArrowDown } from "react-icons/md"

import AC_Logo from './ac_logo.svg'
import CS_Logo from './logo.svg'
import styled from '@emotion/styled'
import MemberIcon from '../../components/MemberIcon'
import { push } from 'connected-react-router'

const HeaderNav = styled.div `
  height: 60px;
  background-color: #FFFFFF;
  box-shadow: 0 0 9px 0 rgba(0,0,0,.13);
`
const CompanyName = styled.div `
  color: gray;
`

class Nav extends React.Component {
  render() {
    const { logout, user, push } = this.props
    return (
      <HeaderNav className='px2 flex items-center justify-between'>
      { user.isAuthed ? (
        <>
          <Link to='/'>
            <div className='flex items-center'>
              <img width="160px" src={AC_Logo} alt="Air Canada Logo"/>
            </div>
          </Link>
          <ButtonDropdown
            button={{
              className: 'link',
              text: (
                <div className='flex items-center'>
                  <MemberIcon name={user.details.name} />
                  <div className='right-align mr1'>
                    <div>{user.details.name}</div>
                    <CompanyName className='small block'>{user.details.organization.name}</CompanyName>
                  </div>
                  <MdKeyboardArrowDown size={25}/>
                </div>
              )
            }}
            dropdown={{
              clickCloses: true,
              content: [
                {
                  text: 'Dashboard',
                  icon: 'dashboard',
                  onClick: () => push('/'),
                },
                {
                  text: 'Account Management',
                  icon: 'account_circle',
                  onClick: () => push('/account')
                },
                {
                  text: 'Team Management',
                  icon: 'group',
                  onClick: () => push('/team')
                },
                {
                  text: 'Sign Out',
                  icon: 'exit_to_app',
                  onClick: logout,
                }
              ]
            }}
          />
        </>
      ) : (
        <div className='flex items-center'>
          <img width="120px" src={CS_Logo} alt="Cinesend Logo"/> 
          <strong className='ml2'>In-Flight Entertainment</strong>
        </div>
      )}
      </HeaderNav> 
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  logout: bindActionCreators(logout, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
