import React from 'react';
import { connect } from 'react-redux' 
import { bindActionCreators } from 'redux'
import { Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import styled from '@emotion/styled'
import Auth from './containers/Auth'
import Reset from './containers/Reset'
import ResetToken from './containers/ResetToken'
import Nav from './containers/Nav'
import Home from './pages/Home'
import Team from './pages/Team'
import Invite from './pages/Invite'
import NotFound from './pages/NotFound'
import Account from './pages/Account'
import PoweredBy from './components/PoweredBy'
import GlobalLoader from './components/GlobalLoader'
import { getUser } from './api/user'
import PaginationStyles from './styles/pagination'
import '@bitcine/cs-theme/dist/index.scss'
import 'react-toastify/dist/ReactToastify.css'

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`

const ContentContainer = styled.div`
  padding-bottom: 65px;
`

class App extends React.Component {
  componentDidMount() {
    this.props.getUser()

    window.addEventListener("beforeunload", this.alertUserIfUploadInProgress)
  }
  alertUserIfUploadInProgress = e => {
    if (this.props.uploadingInProgress) {
      e.preventDefault()
      return e.returnValue = 'You have uploads in progress! Please wait until they finish uploading before closing this window.'
    }
  }
  render() {
    const { user } = this.props
    return (
      <>
        <PaginationStyles />
        {user.status === 'PENDING' ? (
          <GlobalLoader />
        ) : (
          <>
            {user.isAuthed ? (
              <PageContainer>
                <ContentContainer>
                  <Nav />
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/account" component={Account} />
                    <Route exact path="/team" component={Team} />
                    <Route exact path="/invitation/:token" component={Invite} />
                    <Route path="*" component={NotFound} />
                  </Switch>
                </ContentContainer>
                <PoweredBy />
              </PageContainer>
            ) : (
              <>
                <Switch>
                  <Route exact path="/invitation/:token" component={Invite} />
                  <Route exact path="/reset" component={Reset} />
                  <Route exact path="/reset_token/:token" component={ResetToken} />
                  <Route path="*" component={Auth} />
                </Switch>
              </>
            )}
          </>
        )}
        <ToastContainer />
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  uploadingInProgress: state.distributor_uploads.list.filter(l => l.status === 'uploading').length > 0
})

const mapDispatchToProps = dispatch => ({
  getUser: bindActionCreators(getUser, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
