import {
  LOGIN_PENDING,
  LOGIN_REJECTED,
  LOGIN_FULFILLED,
  UPDATE_AUTH_FIELD,
  SET_JWT_TOKEN,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_FULFILLED,
  RESET_PASSWORD_REJECTED,
  SET_PASSWORD_PENDING,
  SET_PASSWORD_FULFILLED,
  SET_PASSWORD_REJECTED
} from './types'

const initialState = {
  isSubmitting: false,
  hasFailed: false,
  emailAttempt: '',
  passwordAttempt: '',
  jwtToken: null,
  resetEmailSent: false,
  newPassword: ''
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOGIN_PENDING: 
    case RESET_PASSWORD_PENDING:
    case SET_PASSWORD_PENDING:
      return {
        ...state,
        isSubmitting: true,
        resetEmailSent: false
      }
    case LOGIN_REJECTED: 
    case RESET_PASSWORD_REJECTED:
    case SET_PASSWORD_REJECTED:
      return {
        ...state,
        isSubmitting: false,
        hasFailed: true
      }
    case LOGIN_FULFILLED: 
    case RESET_PASSWORD_FULFILLED:
      return {
        ...state,
        isSubmitting: false,
        hasFailed: false,
        resetEmailSent: true,
        emailAttempt: '',
        passwordAttempt: ''
      }
    case UPDATE_AUTH_FIELD:
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    case SET_JWT_TOKEN:
      return {
        ...state,
        jwtToken: action.payload
      }
  default:
    return state
  }
}