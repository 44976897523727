import React from 'react';
import CSLogo from '../../img/logo.svg'
import styled from '@emotion/styled'

const Footer = styled.div`
  width: 100%;
  opacity: 35%;
  position: absolute;
  bottom: 0;
  height: 65px;
`

class PoweredBy extends React.Component {
  render() {
    return (
      <>
        <Footer className='flex justify-center'>
          <div className='pb2'>
            <div className='flex justify-center'>
              <h5 className='muted'>Powered by</h5>
            </div>
            <div className='flex justify-center'>
              <a href='https://cinesend.com'>
                <img src={CSLogo} width="100" alt="Cinesend"/>
              </a>
            </div>
          </div>
        </Footer>
      </>
    )
  }
}

export default PoweredBy
