import {
  LOGIN,
  UPDATE_AUTH_FIELD,
  LOGOUT,
  RESET_PASSWORD,
  SET_PASSWORD
} from '../../redux/auth/types'
import { toast } from "react-toastify"
import { post, put } from '../fetch'
import { push } from 'connected-react-router'


export const login = () => (dispatch, getState) => dispatch(
  post(LOGIN, `auth`, {
    email: getState().auth.emailAttempt, 
    password: getState().auth.passwordAttempt
  })
)

export const updateField = (field, value) => dispatch => dispatch({
  type: UPDATE_AUTH_FIELD,
  payload: {
    field, value
  }
})

export const logout = () => dispatch => {
  localStorage.setItem('token', '')

  return dispatch({
    type: LOGOUT
  })
}

export const resetPassword = () => (dispatch, getState) => dispatch(
  post(RESET_PASSWORD, `auth/reset`, { 
    email: getState().auth.emailAttempt
  })
)

export const setPassword = (email, token) => (dispatch, getState) => dispatch(
  put(SET_PASSWORD, `auth/reset`, { email, token, password: getState().auth.newPassword }, (res) => {
    if(res.success) {
      dispatch(updateField('isSubmitting', false))
      dispatch(updateField('hasFailed', false))
      dispatch(updateField('emailAttempt', ''))
      dispatch(updateField('passwordAttempt', ''))
      dispatch(updateField('resetEmailSent', false))
      dispatch(updateField('newPassword', ''))
      dispatch(push('/'))
      toast.success("Password updated. You may now login with your new password.", {
        position: toast.POSITION.BOTTOM_RIGHT
      })
    }
  })
)