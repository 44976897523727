/** @jsx jsx */
import React from 'react'
import { css, jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const widths = [
  '30%', '22%', '18%', '10%', '32%', '24%', '20%', '30%', '22%', '28%', '14%', '24%'
]

const shine = keyframes`
  0% { transform:translateX(-100%); }
  100% { transform:translateX(100%); }
`

const Row = styled.div`
  opacity: 0.75;
  height: 40px;
  width: 100%;
  background: #f1f1f1;
  z-index:0;
  position: relative;
  overflow: hidden;
  &:after {
    content:'';
    top:0;
    transform: translateX(100%);
    width: 100%;
    height: 40px;
    position: absolute;
    z-index:1;
    animation: ${props => props.animate ? css`${shine} 2s infinite` : `none`};
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 );
  }
`

const Name = styled.div`
  width: ${props => props.randWidth};
  height: 20px;
  background: #dedede;
  border-radius: 5px;
`

const Tag = styled.div`
  width: 30px;
  height: 20px;
  background: #dedede;
  border-radius: 5px;
`
const Wrap = styled.div`
  position: relative;
`
const Grad = styled.div`
  z-index: 2;
  position: absolute;
  top: 40px; left: 0; bottom: 0; right: 0;
  background: rgb(249,249,249);
  background: linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(249,249,249,0) 100%);
`
const NoResultsMessage = styled.div`
  opacity: ${props => props.show ? '0.4' : '0'};
  z-index: 5;
  text-align: center;
  color: #333;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 250ms;

  i {
    font-size: 2.25em;
  }

  span {
    text-transform: uppercase;
  }
`
class SkeletonLoader extends React.Component {
  static defaultProps = {
    isLoading: true,
    hasResults: true
  }
  render() {
    const { isLoading, hasResults } = this.props
    if (!isLoading && hasResults) return null
    return (
      <Wrap>
        <NoResultsMessage show={!isLoading && !hasResults}>
          <div className='mb1'><i className="fal fa-align-slash"></i></div>
          <span>No results</span>
        </NoResultsMessage>
        <Grad />
        {[...Array(12)].map((a, i) => 
          <Row key={i} animate={isLoading}>
            <div className='flex items-center px2' css={css`height: 100%;`}>
              <div className='flex-auto'>
                <Name randWidth={widths[i]} />
              </div>
              <Tag />
            </div>
          </Row>
        )}
      </Wrap>
    )
  }
}

export default SkeletonLoader