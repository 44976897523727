/** @jsx jsx */
import React from 'react' // eslint-disable-line no-unused-vars
import { css, jsx } from '@emotion/core'
import Spinner from '../Spinner'

const GlobalLoader = () => 
  <div css={css`
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: #f3f3f3;
  `}>
    <div css={css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}>
      <Spinner />
    </div>
  </div>

export default GlobalLoader