/** @jsx jsx */
import React from 'react'
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import config from '../../config'


const TagWrapper = styled.span`
  padding: 4px 6px;
  border-radius: 3px;
  background: ${props => config.colors[props.color]};
  color: #fff;
  font-size: 0.7em;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: ${config.shadows.light};
  margin-left: 5px;
`

const SuccessState = ({ children }) =>
  <TagWrapper color={'green'}>{children}</TagWrapper>

const FailedState = ({ children }) =>
  <TagWrapper color={'red'}>{children}</TagWrapper>

class Tag extends React.Component {

  static Success = SuccessState
  static Failed = FailedState

  render() {
    return (
      <TagWrapper color={'gray_dark'}>
        {this.props.children}
      </TagWrapper>
    )
  }
}

export default Tag
