import {
  GET_DISTRIBUTOR_UPLOADS,
  DELETE_DISTRIBUTOR_UPLOAD,
} from '../../redux/distributor_uploads/types'

import { get, del } from '../fetch'

export const getDistributorUploads = (page = 0) => dispatch => 
  dispatch(get(GET_DISTRIBUTOR_UPLOADS.REQUEST, `distributor/uploads?page=${page}`))

export const deleteFailedDistributorUpload = (id) => (dispatch) =>
  dispatch(del(DELETE_DISTRIBUTOR_UPLOAD.REQUEST, `distributor/uploads/${id}`))