/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { updateField, setPassword } from '../../api/auth'
import { bindActionCreators } from 'redux'
import { Form, Input, Button, Card, Error } from '../../components/AuthForm'
import Nav from '../Nav/index'
import { parseUrl } from 'query-string'

import '../../index.css'
import './index.css'

import ImageLow from '../../img/seats_faded@lowrez.jpg'
import ImageHigh from '../../img/seats_faded@1080.jpg'

class ResetToken extends React.Component {
  constructor() {
    super()
    this.image = null
    this.state = {
      src: ImageLow
    }
  }
  componentDidMount() {
    this.image = new Image()
    this.image.src = ImageHigh
    this.image.onload = () => this.setState({ src: ImageHigh })


  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { auth, updateField, setPassword } = this.props
    const email = parseUrl(this.props.location.search).query.email
    const token = this.props.match.params.token
    return (
      <div>
        <Nav />
        <div className="not-authed-body" css={css`
          background-image: url(${this.state.src});
        `}>
          <div className="auth-form">
            <Card className='mb2'>
              <h1 className='mb2'>Password Reset</h1>
              <Form onSubmit={(e) => {
                e.preventDefault()
              }}>
                <Input
                  autoFocus
                  type="text" 
                  value={email} 
                  onChange={() => {}}
                  disabled={true} />
                <Input
                  autoFocus
                  type="password" 
                  value={auth.newPassword} 
                  onChange={e => updateField('newPassword', e.target.value )}
                  placeholder="Enter your new password..." 
                  disabled={auth.isSubmitting} />
                <Button onClick={() => setPassword(email, token)} disabled={auth.isSubmitting || !auth.newPassword.length}>Set Password</Button>
                {auth.hasFailed && (
                  <Error className="mt2">Invalid token / email</Error>
                )}
              </Form>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  updateField: bindActionCreators(updateField, dispatch),
  setPassword: bindActionCreators(setPassword, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetToken)