/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { login, updateField } from '../../api/auth'
import { bindActionCreators } from 'redux'
import { Form, Input, Button, Card, Error } from '../../components/AuthForm'
import { Link } from 'react-router-dom'
import Nav from '../Nav/index'

import '../../index.css'
import './index.css'

import ImageLow from '../../img/seats_faded@lowrez.jpg'
import ImageHigh from '../../img/seats_faded@1080.jpg'

class Login extends React.Component {
  constructor() {
    super()
    this.image = null
    this.state = {
      src: ImageLow
    }
  }
  componentDidMount() {
    this.image = new Image()
    this.image.src = ImageHigh
    this.image.onload = () => this.setState({ src: ImageHigh })
  }
  componentWillUnmount() {
    if (this.image) {
      this.image.onload = null
      this.image = null
    }
  }
  render() {
    const { auth, login, updateField } = this.props
    return (
      <div>
        <Nav />
        <div className="not-authed-body" css={css`
          background-image: url(${this.state.src});
        `}>
          <div className="auth-form">
            <Card className='mb2'>
              <h1>Sign in</h1>
              <p>Please enter your details below</p>
              <Form onSubmit={(e) => {
                e.preventDefault()
              }}>
                <Input
                  autoFocus
                  type="text" 
                  value={auth.emailAttempt} 
                  onChange={e => updateField('emailAttempt', e.target.value )}
                  placeholder="Enter your email..." 
                  disabled={auth.isSubmitting} />

                <Input 
                  type="password" 
                  value={auth.passwordAttempt} 
                  onChange={e => updateField('passwordAttempt', e.target.value )}
                  placeholder="Enter your password..."
                  disabled={auth.isSubmitting} />

                <Button onClick={login} disabled={auth.isSubmitting}>Log In</Button>

                {auth.hasFailed && (
                  <Error className="mt2">Invalid Credentials</Error>
                )}
              </Form>
            </Card>
            <Card>
              <Link to='/reset'>Reset your password</Link>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  login: bindActionCreators(login, dispatch),
  updateField: bindActionCreators(updateField, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)