import React from 'react'
import { Global, css } from '@emotion/core'

const PaginationStyles = () =>
  <Global styles={css`
    .cs-paginate {
      li {
        &.break {
          display: block;
          background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
        }
        a {
          cursor: pointer;
          background: linear-gradient(0deg, #f7f7f7 0%, #ffffff 100%);
          display: block;
        }
        &.previous {
          margin-right: 1rem;
        }
        &.next {
          margin-left: 1rem;
        }
        &.selected {
          a {
            background: linear-gradient(0deg, #d9d9d9 0%, #f3f3f3 100%);
            font-weight: bold;
          }
        }
        &.next,
        &.previous {
          a {
            border-radius: 3px;
            border: 1px solid #c7cdcf;
            color: #424a50;
            font-weight: bold;
            padding: 0 0.5rem;
          }
        }
        &:not(.next),
        &:not(.previous) {
          a {
            padding: 0 1rem;
            border-top: 1px solid #c7cdcf;
            border-bottom: 1px solid #c7cdcf;
            border-left: 1px solid #c7cdcf;
          }
        }
        &:nth-of-type(2) {
          a {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }
        }
        &:nth-last-of-type(2) {
          a {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-right: 1px solid #c7cdcf;
          }
        }
      }
    }
  `} />

export default PaginationStyles
