import React from 'react'
import DistributorUploads from '../../containers/DistributorUploads'
import AsperaButton from '../../components/AsperaButton'

class Home extends React.Component {
  render() {
    return (
      <div className="mx-auto py2 px3">
        <div className='flex items-center justify-between mb1'>
          <h3 className='flex-auto'>All Files</h3>
          <AsperaButton />
        </div>
        <DistributorUploads />
      </div>
    )
  }
}

export default Home